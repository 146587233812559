import React, { Component } from 'react';

var createReactClass = require('create-react-class');

const wrapTags = (text, regex) => {
    var matches = text.matchAll(regex);
    // console.log(Array.from(matches));
    var result = [];
    var cursor = 0;
    for (let m of Array.from(matches)) {
        result.push(text.slice(cursor, m.index));
        result.push(<a href={m[2]}>{m[1]}</a>);
        cursor = m.index + m[1].length + m[2].length + 4;
        console.log(m.index);
    }
    result.push(text.slice(cursor, text.length));
    return result;
};

// Find links
const md_link_regex = /(?=\[(!\[.+?\]\(.+?\)|.+?)]\((https:\/\/[^\)]+)\))/gi
// let links = [...str.matchAll(regex)].map((m) => ({ text: m[1], link: m[2] }))

class Resume extends Component {
    render() {

        if (this.props.data) {
            var skillmessage = this.props.data.skillmessage;
            var education = this.props.data.education.map(function (education) {
                return <div key={education.school}><h3>{education.school}</h3>
                    <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
                    <p>{wrapTags(education.description, md_link_regex)}</p>
                    </div>
            })
            var work = this.props.data.work.map(function (work) {
                return <div key={work.company}><h3>{work.company}</h3>
                    <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
                    <p>{wrapTags(work.description, md_link_regex)}</p>
                </div>
            })
            var skills = this.props.data.skills.map(function (skills) {
                var className = 'bar-expand ' + skills.name.toLowerCase();
                return <li key={skills.name}><span style={{ width: skills.level }} className={className}></span><em>{skills.name}</em></li>
            })
            var publications = this.props.data.publications.map(function (pub) {
                return <div key={pub.title}><h5><a href={pub.link1}>{pub.title}</a></h5>
                    <p className="info">{pub.authors}<span>&bull;</span> <em className="date">{pub.date}</em></p>
                    <p><a href={pub.link2}>{pub.description}</a></p>
                </div>
            })
        }

        return (
            <section id="resume">

                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        <div className="row item">
                            <div className="twelve columns">
                                {education}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row work">

                    <div className="three columns header-col">
                        <h1><span>Work</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        {work}
                    </div>
                </div>

                <div className="row pubs">

                    <div className="three columns header-col">
                        <h1><span>Publications</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        {publications}
                    </div>
                </div>

                {/* <div className="row skill">
                    <div className="three columns header-col">
                        <h1><span>Skills</span></h1>
                    </div>

                    <div className="nine columns main-col">

                        <p>{skillmessage}
                        </p>

                        <div className="bars">
                            <ul className="skills">
                                {skills}
                            </ul>
                        </div>
                    </div>
                </div> */}

            </section>
        );
    }
}

export default Resume;
