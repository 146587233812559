import React, { Component } from 'react';

class Portfolio extends Component {
    render() {

        if (this.props.data) {
            var projects = this.props.data.projects.map(function (projects) {
                var projectImage = 'images/portfolio/' + projects.image;
                return <div key={projects.title} className="columns portfolio-item">
                    <div className="item-wrap">

                        <img alt={projects.title} src={projectImage} />
                        <div className="overlay">
                            <div className="portfolio-item-meta">
                                <h5>{projects.title}</h5>
                                <p>{projects.category}</p>
                                <a href={projects.url} title={projects.title}>Project Page</a>
                                <a href={projects.repo_url} title={projects.title}>Github Repo</a>
                            </div>
                        </div>
                        {/* <div className="link-icon"><i className="fa fa-link"></i></div> */}

                    </div>
                </div>
            })
        }

        return (
            <section id="portfolio">

                <div className="row">

                    <div className="twelve columns collapsed">

                        <h1>Check Out Some of My Projects.</h1>

                        <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                            {projects}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <span>The projects I did in high school can be found on a <a href="https://mspries.github.io/">larger portfolio from the Milton Academy course "Applied Mathematics and Artificial Intelligence"</a> taught by <a href="http://emilypries.com/">Ms. Emily Pries</a>.</span>
                </div>

            </section>
        );
    }
}

export default Portfolio;
